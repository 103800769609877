import React from 'react';

import { Section, Container, Smallprint } from '@components/global';

const Impressum = () => (
  <Section id="header">
    <Container>
      <h3>Kontaktadresse</h3>
      <Smallprint>
        hand nail & foot, Vreni Messerli
        <br />
        Akazienweg 36
        <br />
        3184 Wünnewil
        <br />
        Schweiz
        <br />
        kontakt@handnailfoot.ch
      </Smallprint>

      <h3>Vertretungsberechtigte Personen</h3>
      <Smallprint>Vreni Messerli, Inhaberin</Smallprint>

      <h3>Haftungsausschluss</h3>
      <Smallprint>
        Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
        Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und
        Vollständigkeit der Informationen. Haftungsansprüche gegen den Autor
        wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff
        oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen,
        durch Missbrauch der Verbindung oder durch technische Störungen
        entstanden sind, werden ausgeschlossen. Alle Angebote sind
        unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
        Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
        verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
        oder endgültig einzustellen.
      </Smallprint>

      <h3>Haftung für Links</h3>
      <Smallprint>
        Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
        Verantwortungsbereichs Es wird jegliche Verantwortung für solche
        Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
        erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
      </Smallprint>

      <h3>Urheberrechte</h3>
      <Smallprint>
        Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
        anderen Dateien auf der Website gehören ausschliesslich Hand Nail Foot
        Vreni Messerli oder den speziell genannten Rechtsinhabern. Für die
        Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der
        Urheberrechtsträger im Voraus einzuholen.
      </Smallprint>
    </Container>
  </Section>
);

export default Impressum;
